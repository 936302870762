/* Attendance.css */

#attendance {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1rem;
  background-size: cover;
  background-position: center;
  background-color: #f3f4f6; /* Equivalent to bg-gray-100 */
}

.attendance-container {
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* shadow-md */
  border-radius: 0.5rem; /* rounded-lg */
  padding: 2rem; /* p-8 */
  max-width: 28rem; /* max-w-md */
  width: 100%;
  text-align: center;
}

.attendance-title {
  font-size: 1.5rem; /* text-2xl */
  font-weight: bold; /* font-bold */
  margin-bottom: 0.5rem; /* mb-2 */
}

.attendance-description {
  font-size: 0.875rem; /* text-sm */
  color: #4b5563; /* text-gray-600 */
  margin-bottom: 1.5rem; /* mb-6 */
}

.attendance-form {
  display: grid;
  gap: 1rem; /* space-y-4 */
}

.attendance-input {
  padding: 0.75rem 0.625rem; /* Uniform padding for top/bottom and left */
  border: 1px solid #d1d5db; /* border */
  border-radius: 0.375rem; /* rounded */
  outline: none;
  transition: box-shadow 0.2s;
}

.attendance-input:focus {
  box-shadow: 0 0 0 2px #3b82f6; /* focus:ring-blue-500 */
}

.attendance-button {
  width: 100%;
  background-color: #3b82f6; /* bg-blue-500 */
  color: white;
  padding: 0.75rem 0.625rem; /* Match padding to the input */
  border-radius: 0.375rem; /* rounded */
  transition: background-color 0.3s; /* transition-colors duration-300 */
  border: none; /* Remove the border */
}

.attendance-button:hover {
  background-color: #2563eb; /* hover:bg-blue-600 */
}

.attendance-note {
  font-size: 0.75rem; /* text-xs */
  color: #6b7280; /* text-gray-500 */
  margin-top: 1rem; /* mt-4 */
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5); /* bg-black bg-opacity-50 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  border-radius: 0.5rem; /* rounded-lg */
  padding: 1.5rem; /* p-6 */
  text-align: center;
  max-width: 20rem; /* max-w-sm */
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* shadow-lg */
}

.modal-close-button {
  position: absolute;
  top: 0.5rem; /* top-2 */
  right: 0.5rem; /* right-2 */
  color: #9ca3af; /* text-gray-400 */
  transition: color 0.2s;
}

.modal-close-button:hover {
  color: #4b5563; /* hover:text-gray-600 */
}

.modal-icon {
  font-size: 3rem; /* text-5xl */
  margin-bottom: 1rem; /* mb-4 */
}

.modal-icon-success {
  color: #10b981; /* text-green-500 */
}

.modal-icon-error {
  color: #ef4444; /* text-red-500 */
}

.modal-message {
  font-size: 1.125rem; /* text-lg */
  font-weight: 600; /* font-semibold */
  margin-bottom: 1rem; /* mb-4 */
}

.modal-button {
  margin-top: 1rem; /* mt-4 */
  padding: 0.5rem 1.5rem; /* px-6 py-2 */
  border-radius: 0.375rem; /* rounded */
  transition: background-color 0.3s; /* transition-colors duration-300 */
  color: white;
}

.modal-button-success {
  background-color: #10b981; /* bg-green-500 */
}

.modal-button-success:hover {
  background-color: #059669; /* hover:bg-green-600 */
}

.modal-button-error {
  background-color: #ef4444; /* bg-red-500 */
}

.modal-button-error:hover {
  background-color: #dc2626; /* hover:bg-red-600 */
}
