@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}

.min-h-screen {
  min-height: 100vh;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-norepeat {
  background-repeat: no-repeat;
}

.flex {
  display: flex;
}
.flex1 {
  display: flex;
}
.flex2 {
  display: flex;
}

.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.m-2 {
  margin: 0.5rem;
}

.m-4 {
  margin: 1rem;
}

.p-8 {
  padding: 2rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-2 {
  margin-top: 1rem;
}
.mt-7 {
  margin-top: 7rem;
}
.mb-7 {
  margin-bottom: 7rem;
}

.rounded {
  border-radius: 0.5rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-5xl {
  max-width: 50rem;
}

.w-full {
  width: 100%;
}

.text-xxl {
  font-size: 0.8rem;
}

.text-2xl {
  font-size: 1rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-lg {
  font-size: 1rem;
}

.font-bold {
  font-weight: 600;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.text-blue-600 {
  color: #2563eb;
}

.underline {
  text-decoration: underline;
}

.bg-blue-600 {
  background-color: #1d4ed8;
}

.text-white {
  color: #ffffff;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-6 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-8 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.bg-white {
  background-color: #ffffff;
}

.w-8 {
  width: 2rem;
}

.h-8 {
  height: 2rem;
}

.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.gap-4 {
  gap: 1rem;
}
.gap-2 {
  gap: 0.5rem;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.bg-blue-600 {
  background-color: #2c255f;
}

.text-white {
  color: #ffffff;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-l {
  border-radius: 1rem;
}

.underline {
  text-decoration: underline;
}

.input-field {
  border: 1px solid #cccccc;
  padding: 0.5rem;
  padding-inline: 1rem; /* Padding for placeholder */
  border-radius: 0.25rem;
  width: 100%;
  box-sizing: border-box;
  outline: none;
}

.radio-group {
  display: flex;
  align-items: center;
}

.radio-group label {
  margin-right: 0.5rem;
}

.radio-option {
  font-size: 14px;
}

.button {
  background-color: #2c255f;
  color: white;
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
.button-join {
  background-color: #5f4825;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background-color: #241d4d;
}

.button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.logo {
  width: 120px;
  height: 140px;
}

.link {
  color: #5548a6;
  font-size: 14px;
}
.gap-6 {
  gap: 6rem;
}
.text-blue-500 {
  color: blue;
  font-size: 16px;
}
.text-red-500 {
  color: red;
  font-size: 8px;
}
.text-red-600 {
  color: red;
  font-size: 16px;
}

.h-color {
  font-weight: 600;
}
.text-white {
  color: white;
}

.space-x-4 {
  gap: 1rem;
}
.recaptcha-container {
  display: flex;

  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.recaptcha-container div {
  width: auto;
  display: inline-block;
}
.terms {
  color: #1d4ed8;
}
/* Responsive styles for all standard screen sizes */
@media (max-width: 1675px) {
  .button {
    font-size: 0.9rem;
  }
  a {
    font-size: 0.9rem;
  }
  .header {
    width: 100%;
    height: 100px;
  }
}
@media (max-width: 1530px) {
  .header {
    width: 100%;
    height: 100px;
  }
  .max-w-5xl {
    max-width: 60%;
  }
}
@media (max-width: 1440px) {
  .grid-cols-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .logo {
    width: 140px;
    height: auto;
  }

  .max-w-5xl {
    max-width: 90%;
  }
  .max-w-5xl {
    max-width: 50%;
  }
}

@media (max-width: 1280px) {
  .grid-cols-3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .px-8 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .p-8 {
    padding: 1.5rem;
  }

  .header {
    width: 80%;
    height: auto;
  }

  .logo {
    width: 120px;
    height: auto;
  }

  .max-w-5xl {
    max-width: 80%;
  }

  .button {
    padding: 0.75rem 1rem; /* Reduced size for the button */
  }
}

@media (max-width: 1280px) {
  .grid-cols-3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .flex {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex1 {
    flex-direction: column;
    justify-content: center;
  }

  .px-8 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .py-9 {
    padding-top: 100px;
  }
  .gap-6 {
    gap: 1rem;
  }

  .p-8 {
    padding: 1.5rem;
  }

  .header {
    width: 100%;
    height: 200px;
  }

  .logo {
    width: 100px;
    height: auto;
  }

  .max-w-5xl {
    max-width: 70%;
  }

  .button {
    padding: 0.75rem 1rem; /* Reduced size for the button */
  }

  .flex-column {
    flex-direction: column; /* Stack header and form */
  }
}

@media (max-width: 1000px) {
  .header {
    width: 100%;
    height: 150px;
  }
  .px-8 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .max-w-5xl {
    max-width: 80%;
  }
}

@media (max-width: 780px) {
  .grid-cols-3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .p-8 {
    padding: 1rem;
  }

  .header {
    width: 70%;
    height: auto;
  }

  .logo {
    width: 80px;
    height: auto;
  }
  .text-less {
    font-size: 0.7rem;
  }
  .max-w-5xl {
    max-width: 80%;
  }

  .button {
    padding: 0.5rem 0.75rem; /* Further reduced size for the button */
  }

  .flex-column {
    flex-direction: column; /* Stack header and form */
  }
  .recaptcha-container {
    justify-content: flex-start;
  }
}

@media (max-width: 640px) {
  .grid-cols-3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .px-8 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .p-8 {
    padding: 1rem;
  }

  .header {
    width: 60%;
    height: auto;
  }

  .logo {
    width: 60px;
    height: auto;
  }

  .max-w-5xl {
    max-width: 80%;
  }
  .button {
    padding: 0.5rem 0.75rem; /* Further reduced size for the button */
  }

  .flex-column {
    flex-direction: column; /* Stack header and form */
  }
}

@media (max-width: 480px) {
  .grid-cols-3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .px-8 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .p-8 {
    padding: 0.5rem;
  }

  .header {
    width: 50%;
    height: auto;
  }

  .logo {
    width: 50px;
    height: auto;
  }

  .max-w-5xl {
    max-width: 80%;
  }
  .button {
    padding: 0.5rem 0.75rem; /* Further reduced size for the button */
  }

  .flex-column {
    flex-direction: column; /* Stack header and form */
  }
}
@media (max-width: 409px) {
  .recaptcha-container {
    transform: scale(0.8);
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 360px) {
  .recaptcha-container {
    transform: scale(0.8);
    display: flex;
    justify-content: center;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  text-align: center;
}
.align-end {
  display: flex;
  align-items: flex-end;
}
.back_home {
  text-decoration: none;
  color: #5548a6;
}
