body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: rgba(23, 29, 45, 0.9);
  background-image: url("../assets/pattern.png");
  background-repeat: repeat;
  background-size: contain;
}

html {
  scroll-behavior: smooth;
}

.home-container {
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 1;
}

.logo-container {
  display: flex;
}

.logo1 {
  width: 80px;
  height: 100px;
  margin-left: 10px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 25px;
  padding-right: 40px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  transition: all linear 0.3s;
}
.nav-links a:hover {
  color: rgba(60, 0, 226);
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  padding-right: 40px;
}

.banner-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 0;
  position: relative;
  background-position: center;
  background-size: cover;
  height: 800px;
  width: 100%;
  overflow: hidden;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner_btn {
  display: flex;
  gap: 10px;
}

.carousel-navigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.carousel-button {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
}

.carousel-button.left {
  left: 10px;
}

.carousel-button.right {
  right: 10px;
}

.carousel-indicators {
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 5px;
}

.indicator {
  width: 15px;
  height: 15px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
}

.indicator.active {
  background: rgba(60, 0, 226);
}

.banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.banner-content h1 {
  font-size: 60px;
}

.banner-content p {
  font-size: 18px;
  max-width: 800px;
}

.banner-content h2 {
  margin-top: 20px;
}

.about-section,
.events-section,
.speakers-section,
.opportunities-section,
.save-spot-section,
.overview-section,
.contact-section {
  padding: 60px 20px;
  text-align: center;
  color: white;
}

.section-header {
  color: #fff;
  font-size: 40px;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.card {
  background: rgba(0, 0, 0, 0.1);
  padding: 20px 40px 20px 40px;
  border-radius: 10px;
  width: 300px;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  margin-top: 100px;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.card-align {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.about-list-holder {
  margin: 0;
  padding: 0;
}

.about-list {
  list-style-type: none;
  font-size: 12px;
  padding-bottom: 10px;
  text-align: left;
}

.event-card {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-bottom: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.event-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.width-event {
  width: 30%;
  padding: 40px;
}

.event-body {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
}

.event-description {
  text-align: start;
}

.speaker-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 40px;
}

.speaker-card {
  background: rgb(0, 0, 0, 0.2);
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
  width: 300px;
  height: 540px;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.speaker-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.speaker-card img {
  width: 100%;
  height: 50%;
  object-fit: cover;
  border-radius: 10px;
}

.speaker_description {
  text-align: start;
  font-size: 12px;
}

.speaker-topic {
  background-color: rgba(0, 0, 0, 1);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.room_one {
  margin-top: 50px;
}
.session_number {
  margin-block-start: 0;
  margin-block-end: 0;
}

.sponsor-header {
  color: #fff;
  font-size: 20px;
}

.sponsor_card_container {
  padding: 0 40px 0 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 100px;
}

.leader {
  font-size: 14px;
  color: #fff;
  padding: 10px 20px;
  background-color: #72788b;
  border-radius: 20px;
}

.sponsor_card_container img {
  width: 15%;
  height: 20%;
  object-fit: cover;
}

.sponsor-card {
  width: 200px;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.sponsor-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #1c1c1c54;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.8);
  transition: all linear 0.5s;
}

.sponsor-card img:hover {
  transform: scale(1.3);
}

.organizers-card div.h2 {
  margin: 0;
  padding: 0;
}

.organizers-header {
  color: #000;
  font-size: 20px;
}

.organizers_card_container {
  padding: 0 40px 0 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.organizers_card_container img {
  width: 20%;
  height: 20%;
  object-fit: cover;
}

.organizers-card {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.organizers-card img {
  width: 70%;
  height: 70%;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #1c1c1c54;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.8);
  transition: all ease-in-out 0.5s;
}

.organizers-card img:hover {
  transform: scale(1.3);
}

.org-name {
  font-size: 14px;
}

.register-button {
  background: rgb(60, 0, 226);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
  transition: all linear 0.5s;
}

.register-button:hover {
  background: rgba(60, 0, 226, 0.6);
}

.register-button-diff {
  background: rgb(52, 24, 131);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
  transition: all linear 0.5s;
}

.register-button-diff:hover {
  background: rgb(52, 24, 131, 0.7);
}

.map {
  margin-top: 20px;
}

.register-button-maps {
  background: rgb(60, 0, 226);
  text-decoration: none;
  color: #fff;
  padding: 7px 20px;
  border-radius: 10px;
}

.collage {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.collage img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.5s;
}

.collage img:hover {
  display: flex;
  justify-content: center;
  align-self: center;
  transform: translate(-10%, -10%) scale(2);
  cursor: pointer;
}

.contact-icons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.contact-icons a {
  color: white;
  text-decoration: none;
  font-size: 24px;
}

.contact-icons a:hover {
  text-decoration: underline;
}

.fab {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

/* Fade-in animation */
.fade-in {
  opacity: 0;
  transform: translateY(200px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}
.day-header {
  font-size: 24px;
  font-weight: lighter;
  margin-top: 40px;
}
.key-note {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
/* Responsive styles */
@media (max-width: 1650px) {
  .banner-content h1 {
    font-size: 34px;
  }

  .banner-content p {
    font-size: 17px;
  }
}

@media (max-width: 1440px) {
  .banner-content h1 {
    font-size: 32px;
  }

  .banner-content p {
    font-size: 16px;
  }
}

@media (max-width: 1280px) {
  .banner-content h1 {
    font-size: 30px;
  }

  .banner-content p {
    font-size: 15px;
  }

  .navbar {
    padding: 10px;
  }

  .sponsor_card_container {
    flex-wrap: wrap;
  }
}

@media (max-width: 1024px) {
  .logo1 {
    width: 80px;
    height: 100px;
  }

  .nav-links {
    display: none;
  }

  .menu-toggle {
    display: block;
  }

  .nav-links.open {
    width: 200px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    padding: 10px 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
  }

  .nav-links a {
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .width-event {
    width: 80%;
  }

  .contact-icons a {
    font-size: 20px;
  }
}

@media (max-width: 780px) {
  .nav-links a {
    padding: 10px;
  }

  .banner-content h1 {
    font-size: 28px;
  }

  .banner-content p {
    font-size: 14px;
  }

  .banner-content h2 {
    font-size: 16px;
  }

  .width-event {
    width: 100%;
    padding: 20px;
  }

  .cards-container {
    width: 90%;
    margin: 0 auto;
  }

  .event-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .width-event {
    width: 80%;
  }

  .collage {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
}

@media (max-width: 645px) {
  .banner-content {
    padding: 20px;
  }

  .banner-content h1 {
    font-size: 24px;
  }

  .banner-content p {
    font-size: 12px;
  }

  .banner-content h2 {
    font-size: 14px;
  }

  .cards-container {
    width: 100%;
    margin: 0 auto;
  }

  .contact-icons {
    flex-wrap: wrap;
  }
}

@media (max-width: 450px) {
  .logo1 {
    width: 80px;
    height: 100px;
  }

  .banner-content h1 {
    font-size: 20px;
  }

  .banner-content p {
    font-size: 10px;
  }

  .banner-content h2 {
    font-size: 12px;
  }

  .contact-icons a {
    font-size: 18px;
  }
  .leader {
    width: 70%;
  }
  .leader h2 {
    font-size: 8px;
  }
  .banner_btn {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Create two equal-width columns */
    gap: 10px; /* Add some space between the buttons */
  }

  .banner_btn a {
    text-align: center; /* Center-align the text within each button */
    font-size: 10px;
  }
  .banner_btn a:nth-child(5) {
    grid-column: span 2; /* Make the last element span both columns */
    justify-self: center; /* Center the element */
  }
}

@media (max-width: 350px) {
  .logo1 {
    width: 80px;
    height: 100px;
  }

  .banner-content h1 {
    font-size: 18px;
  }

  .banner-content p {
    font-size: 9px;
  }

  .banner-content h2 {
    font-size: 10px;
  }

  .contact-icons a {
    font-size: 16px;
  }
}

.banner-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
